<template>
  <div class="bx-flooding bx-flooding--footer">
    <footer class="bx-footer bx-typo--footer">
      <div class="bx-footer__brand-logos">
        <a v-for="brandLogo in filteredBrandLogos"
           :key="brandLogo.name"
           :href="brandLogo.url"
           :title="brandLogo.name">
          <svg role="img">
            <use :href="`/images/bravo/bravo_logo_sprite.svg#logo_${brandLogo.portal}`" />
          </svg>
        </a>
      </div>

      <div class="bx-footer__wrapper">
        <div class="bx-grid">
          <SeoHeadline />
          <FooterAboTeaser :page-style="pageStyle" />
          <SocialLinks menu-type="footer" />
        </div>
      </div>

      <div class="bx-footer__menu">
        <div class="bx-grid">
          <MenuList :menu="footerData.menu"
                    menu-type="footer" />
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapState } from 'pinia'

import { usePageStore } from '../../../stores/page'
import MenuList from '../../../components/menu/MenuList.vue'
import SeoHeadline from '../../../components/page-footer/SeoHeadline.vue'
import SocialLinks from '../../../components/shared/SocialLinks.vue'
import FooterAboTeaser from './FooterAboTeaser.vue'

export default {
  components: {
    MenuList,
    SeoHeadline,
    SocialLinks,
    FooterAboTeaser
  },
  props: {
    footerData: {
      type: Object,
      default: () => { return {} }
    }
  },
  data () {
    return {
      footerBrandLogos: [
        { name: 'BRAVO', url: '/', portal: 'bravo' },
        { name: 'DR. SOMMER', url: '/dr-sommer', portal: 'dr-sommer' },
        { name: 'BRAVO GIRL!', url: '/bravo-girl', portal: 'bravo-girl' },
        { name: 'BRAVO SPORT', url: '/bravo-sport', portal: 'bravo-sport' }
      ]
    }
  },
  computed: {
    ...mapState(usePageStore, ['pageData']),
    pageStyle () {
      return this.pageData.pageMeta.style || 'bravo'
    },
    filteredBrandLogos () {
      return this.footerBrandLogos.filter((brandLogo) => brandLogo.portal !== this.pageStyle)
    }
  },
  mounted () {
    if (this.pageData.pageMeta.pageType === 'article') {
      this.$nextTick(function () {
        this.colorTags()
      })
    }
  },
  methods: {
    colorTags () {
      let classPostfix = 1
      document.querySelectorAll('.bx-tags__tag').forEach((t) => {
        t.classList.add('bx-tags__tag--col' + classPostfix)
        classPostfix++
        if (classPostfix > 3) {
          classPostfix = 1
        }
      })
    }
  }
}
</script>
