<template>
  <div class="bx-header__logo-wrapper">
    <a class="bx-header__logo"
       :title="portalTitle"
       :href="getAbsoluteUrl('/')">
      <svg role="img">
        <use :href="`/images/bravo/bravo_logo_sprite.svg#logo_${activePortal}`" />
      </svg>
      <svg role="img">
        <use :href="`/images/bravo/bravo_logo_sprite.svg#logo_${activePortal}_white`" />
      </svg>
    </a>
  </div>
</template>

<script>
import { mapState } from 'pinia'

import { usePageStore } from '../../../stores/page'

export default {
  computed: {
    ...mapState(usePageStore, ['pageData']),
    activePortal () {
      return this.pageData.pageMeta.style ? this.pageData.pageMeta.style : 'bravo'
    },
    portalTitle () {
      const portal = this.activePortal !== 'bravo' ? `/${this.activePortal}` : ''
      return `www.bravo.de${portal}`
    }
  },
  methods: {
    getAbsoluteUrl (url) {
      let portalUrl = this.activePortal !== 'bravo' ? `${url}${this.activePortal}` : url
      if (!import.meta.env.SSR) {
        const a = document.createElement('a')
        a.href = portalUrl
        portalUrl = a.href
      }
      return portalUrl
    }
  }
}
</script>
